import React, { useState } from 'react';
import Customer1 from '../../../assets/home/client/produk motor listrik/charged.png';
import Customer2 from '../../../assets/home/client/produk motor listrik/e-nine.png';
import Customer3 from '../../../assets/home/client/produk motor listrik/electrum.png';
import Customer4 from '../../../assets/home/client/produk motor listrik/minerva.png';
import Customer5 from '../../../assets/home/client/produk motor listrik/smoot.png';

import Customer6 from '../../../assets/home/client/hp/alcatel.png';
import Customer7 from '../../../assets/home/client/hp/aquos.png';
import Customer8 from '../../../assets/home/client/hp/diktiedu.png';
import Customer9 from '../../../assets/home/client/hp/infinix.png';
import Customer10 from '../../../assets/home/client/hp/isafemobile.png';
import Customer11 from '../../../assets/home/client/hp/itel.png';
import Customer12 from '../../../assets/home/client/hp/like edutab.png';
import Customer13 from '../../../assets/home/client/hp/prince.png';
import Customer14 from '../../../assets/home/client/hp/redmagic.png';
import Customer15 from '../../../assets/home/client/hp/ruggear.png';
import Customer16 from '../../../assets/home/client/hp/techno.png';
import Customer17 from '../../../assets/home/client/hp/vfone.png';
import Customer18 from '../../../assets/home/client/hp/wiko.png';
import Customer19 from '../../../assets/home/client/hp/zte.png';

import './Customer.css';
import { useTranslation } from 'react-i18next';
import '../../../i18n.js';
import { useRevealUp } from '../../../components/Animation/RevealUp/RevealUp.js'; 

const images = [
    [
        Customer1, Customer2, Customer3, Customer4,
        Customer5, Customer6, Customer7, Customer8
    ],
    [
        Customer9, Customer10, Customer11, Customer12,
        Customer13, Customer14, Customer15, Customer16
    ],
    [
        Customer17, Customer18, Customer19
    ]
]

export default function Customer() {
    const { t } = useTranslation();
    useRevealUp('.revealUp');
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    return (
        <div className="mb-24 mt-8">
            <div className="flex flex-col items-center mb-14">
                <div className="rectangle w-[10rem] h-1 md:h-3 border-4 border-red-800 bg-red-800 mt-10"></div>
                <div className="mt-12 mx-10 flex flex-wrap sm:flex-nowrap items-center text-center font-bold revealUp">
                    <p className="w-full sm:w-auto text-black text-2xl sm:text-3xl md:text-4xl lg:text-5xl">{t('customer_title_1')}</p>
                    <p className="w-full sm:w-auto text-red-500 ml-2 text-2xl sm:text-3xl md:text-4xl lg:text-5xl">{t('customer_title_2')}</p>
                </div>
            </div>
            <div className="slider-customer-container">
                <div className="slider-customer-content" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                    {images.map((slide, slideIndex) => (
                        <div key={slideIndex} className="slider-customer-slide">
                            {slide.map((image, imgIndex) => (
                                <div key={imgIndex} className="images-customer">
                                    <img src={image} alt={`slide-${slideIndex}-${imgIndex}`} />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <button
                    onClick={prevSlide}
                    className="absolute buttonSlider-customer top-1/2 left-2 sm:left-4 transform -translate-y-1/2 text-white p-2 rounded-full"
                >
                    &#10094;
                </button>
                <button
                    onClick={nextSlide}
                    className="absolute buttonSlider-customer top-1/2 right-2 sm:right-4 transform -translate-y-1/2 text-white p-2 rounded-full"
                >
                    &#10095;
                </button>
                <div className="absolute bottom-[-20px] left-1/2 transform -translate-x-1/2 flex space-x-2">
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`w-4 sm:w-6 lg:w-10 h-1 sm:h-1.5 lg:h-2 ${index === currentIndex ? 'bg-red-600' : 'bg-white'}`}
                        ></div>
                    ))}
                </div>
            </div>
        </div>
    );
}
