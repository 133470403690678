import React from 'react';
import './Product.css';
import { useTranslation } from 'react-i18next';
import '../../../i18n.js';
import { useRevealUp } from '../../../components/Animation/RevealUp/RevealUp.js'; 

export default function Product() {
  const { t } = useTranslation();
  useRevealUp('.revealUp');

    return (
        <div className="mt-5">
            <div className="flex flex-col items-center mb-4 md:mb-10 lg:mb-8 xl:mb-10">
                <div className="rectangle w-[10rem] h-1 md:h-3 border-4 border-red-800 bg-red-800 mt-10"></div>
                <div className="m-2 flex items-center text-[4rem] font-bold revealUp">
                    <p className="text-black text-4xl md:text-5xl lg:text-5xl">{t('product_title_1')}</p>
                    <p className="text-red-500 ml-2 text-4xl md:text-5xl lg:text-5xl">{t('product_title_2')}</p>
                </div>
            </div>
            <div className="flex items-center justify-center bg-gradient-to-b from-red-400 to-red-800">
                <div className="flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 lg:space-x-8 m-4  sm:m-8 md:m-16 lg:m-16">
                    <div className="text-white text-center border-4 border-white rounded-3xl p-4 sm:p-6 md:p-8 lg:p-10 xl:p-5 space-y-4 max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl">
                        <p className="text-[2rem] sm:text-[3rem] md:text-[3.5rem] lg:text-[3.5rem] xl:text-[3.5rem] font-semibold">
                            {t('product_content_title_first')}
                        </p>
                        <p className="text-[1.5rem] sm:text-[2rem] md:text-[2.5rem] xl:text-[2rem] xl:text-[2rem] font-semibold">
                            {t('product_content_desc_first')}
                        </p>
                        <div className="leading-tight">
                            <p className="text-[1rem] sm:text-[1.5rem] md:text-[2rem] lg:text-xl xl:text-[1.5rem]">
                                {t('product_content_support_first')}
                            </p>
                            <p className="text-[1rem] sm:text-[1.5rem] md:text-[2rem] lg:text-xl xl:text-[1.5rem]">
                                {t('product_content_by_first')}
                            </p>
                        </div>
                        <button className="w-[8rem] sm:w-[12rem] md:w-[14rem] lg:w-[12rem] bg-white text-black rounded-full px-6 py-3 text-[1.5rem] sm:text-[2rem] md:text-[2.5rem] lg:text-2xl xl:text-3xl font-semibold">
                            {t('product_content_button_first')}
                        </button>
                    </div>
                    <div className="text-white text-center border-4 border-white rounded-3xl p-4 sm:p-6 md:p-8 lg:p-10 xl:p-5 space-y-4 max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl">
                        <p className="text-[2rem] sm:text-[3rem] md:text-[3.5rem] lg:text-[3.5rem] xl:text-[3.5rem] font-semibold">
                            {t('product_content_title_second')}
                        </p>
                        <p className="text-[1.5rem] sm:text-[2rem] md:text-[2.5rem] xl:text-[2rem] xl:text-[2rem] font-semibold">
                            {t('product_content_desc_second')}
                        </p>
                        <div className="leading-tight">
                            <p className="text-[1rem] sm:text-[1.5rem] md:text-[2rem] lg:text-xl xl:text-[1.5rem]">
                                {t('product_content_support_second')}
                            </p>
                            <p className="text-[1rem] sm:text-[1.5rem] md:text-[2rem] lg:text-xl xl:text-[1.5rem]">
                                {t('product_content_by_second')}
                            </p>
                        </div>
                        <button className="w-[8rem] sm:w-[12rem] md:w-[14rem] lg:w-[12rem] bg-white text-black rounded-full px-6 py-3 text-[1.5rem] sm:text-[2rem] md:text-[2.5rem] lg:text-2xl xl:text-3xl font-semibold">
                            {t('product_content_button_second')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
