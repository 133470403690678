// import logo from './logo.svg';
import './App.css';
import './index.css';
import React from 'react';
// import { BrowserRouter as Router, Routes } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Loading from './components/Loading/Loading';
import Home from './views/Home/Home';
import Contact from './views/Contact/Contact';
import About from './views/About/About';
function App() {
  return (
    // <div className="App">
    //   <h1 className="text-3xl font-bold underline">
    //     Hello world!
    //   </h1>
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <Router>
      <Loading />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
      </Routes>
      <Footer />
  </Router>
  );
}

export default App;
