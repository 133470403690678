import React from 'react';
import Service1 from '../../../assets/home/products/ODM.png';
import Service2 from '../../../assets/home/products/database.png';
import Service3 from '../../../assets/home/products/it support.png';
import Service4 from '../../../assets/home/products/ui-ux.png';
import './Service.css';
import { useTranslation } from 'react-i18next';
import '../../../i18n.js';
import { useRevealUp } from '../../../components/Animation/RevealUp/RevealUp.js'; 

export default function Service() {
    const { t } = useTranslation();
    useRevealUp('.revealUp');

    return (
        <div className="mt-5 md:mx-32">
            <div className="flex flex-col items-center mb-4 md:mb-10 lg:mb-8 xl:mb-10">
                <div className="rectangle w-[10rem] h-1 md:h-3 border-4 border-red-800 bg-red-800 mt-10"></div>
                <div className="m-2 flex items-center text-[4rem] font-bold revealUp">
                    <p className="text-black text-4xl md:text-5xl lg:text-5xl">{t('service_title_1')}</p>
                    <p className="text-red-500 ml-2 text-4xl md:text-5xl lg:text-5xl">{t('service_title_2')}</p>
                </div>
            </div>
            <div className="service-row md:mb-5">
                <div className="service-text revealUp">
                    <p className="text-gray-400 font-semibold text-3xl">{t('service_content_1_title_1')}</p>
                    <div className="flex-item font-bold text-4xl mt-5">
                        <p className="text-black">
                            {t('service_content_1_title_2')} (
                            <span className="text-red-500 font-semibold">
                                {t('service_content_1_title_3')}
                            </span>
                            )
                        </p>
                    </div>
                    <p className="text-justify text-gray-400 mt-5" style={{ fontSize: '1.3rem', lineHeight: '1.7rem'}}>
                        {t('service_content_1_desc')}
                    </p>
                </div>
                <div className="service-image img1 px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12">
                    <img src={Service1} alt="Service 1"/>
                </div>
            </div>
            <div className="service-row">
                <div className="service-image img2 px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12">
                    <img src={Service2} alt="Service 2"/>
                </div>
                <div className="service-text revealUp">
                    <p className="text-gray-400 font-semibold text-3xl">{t('service_content_2_title_1')}</p>
                    <div className="flex-item font-bold text-4xl mt-5">
                        <p className="text-black">
                            {t('service_content_2_title_2')}
                        </p>
                        <p className="text-red-600 mt-5">
                            {t('service_content_2_title_3')}
                        </p>
                    </div>
                    <p className="text-justify text-gray-400 mt-5" style={{ fontSize: '1.3rem', lineHeight: '1.7rem'}}>
                    {t('service_content_2_desc')}
                    </p>
                </div>
            </div>
            <div className="service-row md:mb-5">
                <div className="service-text revealUp">
                    <p className="text-gray-400 font-semibold text-3xl">{t('service_content_3_title_1')}</p>
                    <div className="flex-item font-bold text-4xl mt-5">
                        <p className="text-black">
                            {t('service_content_3_title_2')}
                            <span className="text-red-500 font-semibold">
                                {t('service_content_3_title_3')}
                            </span>
                        </p>
                    </div>
                    <p className="text-justify text-gray-400 mt-5" style={{ fontSize: '1.3rem', lineHeight: '1.7rem'}}>
                        {t('service_content_3_desc')}
                    </p>
                </div>
                <div className="service-image img3 px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12">
                    <img src={Service4} alt="Service 1" className="w-full h-auto object-cover" />
                </div>
            </div>
            <div className="service-row">
                <div className="service-image img4 px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12">
                    <img src={Service3} alt="Service 2" className="w-full h-auto object-cover" />
                </div>
                <div className="service-text revealUp">
                    <p className="text-gray-400 font-semibold text-3xl">{t('service_content_4_title_1')}</p>
                    <div className="flex-item font-bold text-4xl mt-5">
                        <p className="text-black">
                            {t('service_content_4_title_2')}
                            <span className="text-red-600 mt-5">
                                {t('service_content_4_title_3')}
                            </span>
                        </p>
                    </div>
                    <p className="text-justify text-gray-400 mt-5" style={{ fontSize: '1.3rem', lineHeight: '1.7rem'}}>
                        {t('service_content_4_desc')}
                    </p>
                </div>
            </div>
        </div>
    );
}
