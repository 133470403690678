// import React, { useState } from 'react';
import React from 'react';
import header1 from '../../../assets/home/headerFix.jpg';
// import header2 from '../../../assets/home/header2.png';
// import header3 from '../../../assets/home/header3.png';
import './Slider.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../../i18n.js';
import { useRevealUp } from '../../../components/Animation/RevealUp/RevealUp.js'; 
// const images = [header1, header2, header3];

export default function Slider() {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;    
    // const [currentIndex, setCurrentIndex] = useState(0);

    // const nextSlide = () => {
    //     setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    // };

    // const prevSlide = () => {
    //     setCurrentIndex((prevIndex) =>
    //         prevIndex === 0 ? images.length - 1 : prevIndex - 1
    //     );
    // };
    useRevealUp('.revealUp');

    return (
        <div className="slider-container">
            {/* <img src={images[currentIndex]} className="slider-image" alt="slider" /> */}
            <img src={header1} className="slider-image" alt="slider" />
            <div className="absolute top-36 left-6 lg:top-[14rem] lg:left-[10rem] sm:text-left">
                <div className="titleSlider">
                    <div className="text-white text-lg sm:text-3xl md:text-4xl lg:text-5xl xl:text-7xl revealUp font-bold">
                        {t('slider_title_1')}
                    </div>
                    <div className="ml-2 sm:ml-3 md:ml-0 lg:mt-6 text-white text-lg sm:text-3xl md:text-4xl lg:text-5xl xl:text-7xl revealUp">
                        {t('slider_title_2')}
                    </div>
                </div>
                <div className="content flex flex-col sm:flex-row revealUp">
                    <div className="text-white text-[0.8rem] sm:text-lg md:text-2xl lg:text-2xl sm:text-left my-3 sm:my-6 md:my-4 lg:my-6">
                        <p className="mb-1 sm:mb-2 lg:mb-3">{t('slider_content_1')}</p>
                        <p className="mb-1 sm:mb-2 lg:mb-3">{t('slider_content_2')}</p>
                        <p className="lg:mb-3">{t('slider_content_3')}</p>
                    </div>
                </div>
                {/* <button className="mt-3 xs:mt-4 sm:mt-5 lg:mt-6 buttonJourney text-xxs xs:text-xs sm:text-sm md:text-base lg:text-lg px-2 xs:px-3 py-0.5 xs:py-1 sm:px-4 sm:py-2 lg:px-6 lg:py-3">
                    Our Journey
                </button> */}
                <Link className={currentLanguage === 'eng' ? 'btn-eng' : 'btn-ind'}>
                    <span className={currentLanguage === 'eng' ? 'btnInner-eng text-xxs xs:text-xs sm:text-sm md:text-base lg:text-lg px-2 xs:px-3 py-0.5 xs:py-1 sm:px-4 sm:py-2 lg:px-6 lg:py-3' : 'btnInner-ind text-xxs xs:text-xs sm:text-sm md:text-base lg:text-lg px-2 xs:px-3 py-0.5 xs:py-1 sm:px-4 sm:py-2 lg:px-6 lg:py-3'}>
                        {t('slider_button')}
                    </span>
                </Link>
            </div>
            {/* <button
                onClick={prevSlide}
                className="absolute buttonSlider top-1/2 left-2 sm:left-4 transform -translate-y-1/2 text-white p-2 rounded-full"
            >
                &#10094;
            </button>
            <button
                onClick={nextSlide}
                className="absolute buttonSlider top-1/2 right-2 sm:right-4 transform -translate-y-1/2 text-white p-2 rounded-full"
            >
                &#10095;
            </button> */}
            {/* <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                {images.map((_, index) => (
                    <div
                        key={index}
                        className={`w-4 sm:w-6 lg:w-10 h-1 sm:h-1.5 lg:h-2 ${index === currentIndex ? 'bg-red-600' : 'bg-white'}`}
                    ></div>
                ))}
            </div> */}
        </div>
    );
}
