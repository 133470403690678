import React, {useState} from 'react';
import './OurClient.css';
// Electronics and telematics
import ETacico from '../../../assets/about/client/electronicdantelematics/acico.png';
import ETaldo from '../../../assets/about/client/electronicdantelematics/aldo.png';
import ETaqua from '../../../assets/about/client/electronicdantelematics/aqua.png';
import ETaquos from '../../../assets/about/client/electronicdantelematics/aquos.png';
import ETavita from '../../../assets/about/client/electronicdantelematics/avita.png';
import ETdatalite from '../../../assets/about/client/electronicdantelematics/datalite.png';
import ETdlink from '../../../assets/about/client/electronicdantelematics/dlink.png';
import ETfiberhome from '../../../assets/about/client/electronicdantelematics/fiberhome.png';
import EThaier from '../../../assets/about/client/electronicdantelematics/haier.png';
import EThuawei from '../../../assets/about/client/electronicdantelematics/huawei.png';
import ETinfico from '../../../assets/about/client/electronicdantelematics/infico.png';
import ETluby from '../../../assets/about/client/electronicdantelematics/luby.png';
import ETmatrix from '../../../assets/about/client/electronicdantelematics/matrix.png';
import ETmyv from '../../../assets/about/client/electronicdantelematics/myv.png';
import ETnextron from '../../../assets/about/client/electronicdantelematics/nextron.png';
import ETpineri from '../../../assets/about/client/electronicdantelematics/pineri.png';
import ETpioline from '../../../assets/about/client/electronicdantelematics/pioline.png';
import ETprocomp from '../../../assets/about/client/electronicdantelematics/procomp.png';
import ETrocomp from '../../../assets/about/client/electronicdantelematics/rocomp.png';
import ETsanex from '../../../assets/about/client/electronicdantelematics/sanex.png';
import ETsharp from '../../../assets/about/client/electronicdantelematics/sharp.png';
import ETsignalviewer from '../../../assets/about/client/electronicdantelematics/signalviewer.png';
import ETsogo from '../../../assets/about/client/electronicdantelematics/sogo.png';
import ETsuperhd from '../../../assets/about/client/electronicdantelematics/superhd.png';
import ETtaffware from '../../../assets/about/client/electronicdantelematics/taffware.png';
import ETtanaka from '../../../assets/about/client/electronicdantelematics/tanaka.png';
import ETtplink from '../../../assets/about/client/electronicdantelematics/tplink.png';
import ETv2 from '../../../assets/about/client/electronicdantelematics/v2.png';
import ETvannoe from '../../../assets/about/client/electronicdantelematics/vannoe.png';
import ETwelhome from '../../../assets/about/client/electronicdantelematics/welhome.png';
import ETzoan from '../../../assets/about/client/electronicdantelematics/zoan.png';

// Electric motors
import EMcharged from '../../../assets/about/client/produkmotorlistrik/charged.png';
import EMelectrum from '../../../assets/about/client/produkmotorlistrik/electrum.png';
import EMenine from '../../../assets/about/client/produkmotorlistrik/e-nine.png';
import EMminerva from '../../../assets/about/client/produkmotorlistrik/minerva.png';
import EMsmoot from '../../../assets/about/client/produkmotorlistrik/smoot.png';

// Mobile phones, handheld computers and tablets
import MPalcatel from '../../../assets/about/client/hp/alcatel.png';
import MPaquos from '../../../assets/about/client/hp/aquos.png';
import MPdiktiedu from '../../../assets/about/client/hp/diktiedu.png';
import MPinfinix from '../../../assets/about/client/hp/infinix.png';
import MPisafemobile from '../../../assets/about/client/hp/isafemobile.png';
import MPitel from '../../../assets/about/client/hp/itel.png';
import MPlikeedutab from '../../../assets/about/client/hp/likeedutab.png';
import MPprince from '../../../assets/about/client/hp/prince.png';
import MPredmagic from '../../../assets/about/client/hp/redmagic.png';
import MPruggear from '../../../assets/about/client/hp/ruggear.png';
import MPtechno from '../../../assets/about/client/hp/techno.png';
import MPvfone from '../../../assets/about/client/hp/vfone.png';
import MPwiko from '../../../assets/about/client/hp/wiko.png';
import MPzte from '../../../assets/about/client/hp/zte.png';

// Goods and services
import { useTranslation } from 'react-i18next';
import '../../../i18n.js';
import { useRevealUp } from '../../../components/Animation/RevealUp/RevealUp.js'; 

const OurClient = () => {
  const { t } = useTranslation();
  useRevealUp('.revealUp');
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showMore, setShowMore] = useState(false); // State for 'Show More' button
  const tabsData = [
    {
      label: t('our_client_elektro'),
      img: [
        ETacico,ETaldo,ETaqua,ETaquos,ETavita,ETdatalite,ETdlink,ETfiberhome,EThaier,EThuawei,ETinfico,ETluby,ETmatrix,ETmyv,ETnextron,
        ETpineri,ETpioline,ETprocomp,ETrocomp,ETsanex,ETsharp,ETsignalviewer,ETsogo,ETsuperhd,ETtaffware,ETtanaka,ETtplink,ETv2,ETvannoe,
        ETwelhome,ETzoan
      ]
        
    },
    {
      label: t('our_client_motor'),
      img: [
        EMcharged,
        EMelectrum,
        EMenine,
        EMminerva,
        EMsmoot,
      ]
    },
    {
      label: t('our_client_device'),
      img: [
        MPalcatel,
        MPaquos,
        MPdiktiedu,
        MPinfinix,
        MPisafemobile,
        MPitel,
        MPlikeedutab,
        MPprince,
        MPredmagic,
        MPruggear,
        MPtechno,
        MPvfone,
        MPwiko,
        MPzte,
      ]
    },
    {
      label: t('our_client_service'),
      img: [
        EMcharged,
      ]
    },
  ];

  const visibleImages = showMore
    ? tabsData[activeTabIndex].img
    : tabsData[activeTabIndex].img.slice(0, 8);

    return (
      <div className="flex flex-col flex-wrap items-center mt-10 md:mx-20">
        <div className="rectangle w-[10rem] h-1 md:h-3 border-4 border-red-800 bg-red-800"></div>
        <div className="m-2 mt-10 flex items-center text-[4rem] font-bold revealUp">
          <p className="text-black text-4xl md:text-5xl lg:text-5xl">{t('our_client_title_up')}</p>
          <p className="text-red-500 ml-2 text-4xl md:text-5xl lg:text-5xl">{t('our_client_title_down')}</p>
        </div>
        <div className="mx-10 flex border-b gap-[0.5rem] sm:gap-6 md:gap-8 lg:gap-10">
          {tabsData.map((tab, idx) => {
            return (
              <button
                key={idx}
                className={`py-2 border-b-4 transition-colors duration-300 ${idx === activeTabIndex ? 'border-red-500' : 'border-transparent hover:border-gray-200'}`}
                onClick={() => {
                  setActiveTabIndex(idx);
                  setShowMore(false); // Reset 'Show More' when tab changes
                }}
              >
                {tab.label}
              </button>
            );
          })}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-10 object-center justify-center items-center">
          {visibleImages.length > 0 ? (
            visibleImages.map((element, idx) => (
              <div key={idx} className={idx}>
                <img className="image m-8" src={element} alt="Partner" />
              </div>
            ))
          ) : (
            <div className="col-span-full">
              <p className="text-center">No content available</p>
            </div>
          )}
        </div>
  
        {tabsData[activeTabIndex].img.length > 8 && (
          <button
            className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? t('our_client_less') : t('our_client_more')}
          </button>
        )}
      </div>
    );
  };
  
  export default OurClient;